var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.sharedMedia.sharedMediaImagePath,
            _vm.sharedMedia.defaultImg
          ),"deleteFileStatus":false},on:{"changeValue":function($event){_vm.sharedMedia.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.sharedMedia.fullCode,"title":_vm.$t('SharedMedias.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.sharedMedia.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaNameAr"),"errors":_vm.errors_sharedMediaNameAr,"value":_vm.sharedMedia.sharedMediaNameAr,"title":_vm.$t('SharedMedias.titleAr'),"imgName":'sharedMedias.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaNameAr = $event;
            _vm.$v.sharedMedia.sharedMediaNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaNameEn"),"errors":_vm.errors_sharedMediaNameEn,"value":_vm.sharedMedia.sharedMediaNameEn,"title":_vm.$t('SharedMedias.titleEn'),"imgName":'sharedMedias.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaNameEn = $event;
            _vm.$v.sharedMedia.sharedMediaNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaNameUnd"),"value":_vm.sharedMedia.sharedMediaNameUnd,"title":_vm.$t('SharedMedias.titleUnd'),"imgName":'sharedMedias.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaDescriptionAr"),"errors":_vm.errors_sharedMediaDescriptionAr,"value":_vm.sharedMedia.sharedMediaDescriptionAr,"title":_vm.$t('SharedMedias.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaDescriptionAr = $event;
            _vm.$v.sharedMedia.sharedMediaDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaDescriptionEn"),"errors":_vm.errors_sharedMediaDescriptionEn,"value":_vm.sharedMedia.sharedMediaDescriptionEn,"title":_vm.$t('SharedMedias.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaDescriptionEn = $event;
            _vm.$v.sharedMedia.sharedMediaDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaDescriptionUnd"),"value":_vm.sharedMedia.sharedMediaDescriptionUnd,"title":_vm.$t('SharedMedias.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-sharedMediaNotes"),"value":_vm.sharedMedia.sharedMediaNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.sharedMedia.sharedMediaNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }